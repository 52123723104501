export default {
  vehiclesType: {
    CUSTOM: 'Custom',
    AIRPLANE: 'Airplane',
    CAR: 'Car',
    TRAIN: 'Train',
    HIGH_SPEED_TRAIN: 'High Speed Train',
  },
  tourType: {
    DOMESTIC: 'Domestic tour',
    OUTBOUND: 'Outbound tour',
  },

  tableColumns: {
    no: 'No.',
    bookingCode: 'Booking code',
    status: 'Status',
    customerName: 'Customer name',
    tourName: 'Tour name',
    startDate: 'Start date',
    totalPrice: 'Total price',
    totalCommission: 'Total commission',
    createdAt: 'Created at',
    createdBy: 'Created by',
    note: 'Note',
    actions: 'Actions',
    totalPriceToBePaid: 'To be paid',
    totalPriceRefund: 'Refund price',
    depositPrice: 'Deposit / paid',

    paxType: 'Passenger type',
    tourPrice: 'Tour price',
    paxQuantity: 'Quantity',
    // totalPrice: 'Total price',
  },

  status: {
    ALL: 'All',
    RESERVED: 'Reserved',
    BOOKED: 'Booked',
    DEPOSITED: 'Deposited',
    REQUEST_PAYMENT: 'Request Deposit',
    REQUEST_PAYMENT_FULL: 'Request Payment',
    PAID: 'Paid',
    CANCELLATION_REQUESTED: 'Cancellation Requested',
    CANCELLED: 'Cancelled',
  },
}
