export default {
  list: {
    title: 'Ticket List',
    columns: {
      no: 'No',
      bookingCode: 'Booking Code',
      ticketId: 'Ticket Number',
      customerName: 'Customer',
      bookingInfos: 'Booking Infos',
      totalPrice: 'NET price',
      totalPriceTicket: 'Price',
      createdAt: 'Created At',
      createdBy: 'Created By',
      status: 'Status',
      actions: 'Actions',
    },
    and: 'and',
    otherTicket: 'other tickets',
  },
  filter: {
    statusPlaceholder: 'Select status',
    tripDatePlaceholder: 'Trip date',
    bookDatePlaceholder: 'Book date',
    bookingCodePlaceholder: 'Booking code',
    searchTextPlaceholder: 'Search',
    clearFilter: 'Clear filters',
  },
  status: {
    title: 'Status',
    CANCEL: 'Cancel',
    EXPIRED: 'Expired',
    HOLD: 'Hold',
    PAID: 'Paid',
    PROCESSING: 'Processing',
    ALL: 'All',
  },
  detail: {
    contact: {
      title: 'Contacts information',
      fullName: 'Full name',
      identifyNumber: 'Identify Number',
      phoneNumber: 'Phone number',
      email: 'Email',
    },
    bookingInfo: {
      title: 'Booking information',
      columns: {
        no: '#',
        fullName: 'Full name',
        identifyNumber: 'Identify Number',
        ticketId: 'Ticket Number',
        paxType: 'Pax Type',
        seatType: 'Seat Type',
        trip: 'Trip',
        ticketInfo: 'Ticket Info',
        departureTime: 'Departure Time',
        arrivalTime: 'Arrival Time',
        totalPrice: 'Total Price',
        status: 'Status',
        actions: 'Actions',
      },
      showPriceTicket: 'Show service fee',
      hidePriceTicket: 'Hide service fee',
      showProfit: 'Show profit',
      hideProfit: 'Hide profit',
      downloadTicket: 'Download Ticket',
    },
    tripInfo: {
      title: 'Trips Information',
      trip: 'Trip',
      train: 'Train',
    },
    timeHold: 'Timehold',
    createdAt: 'Created at',
    auditLog: {
      title: 'Audit logs',
      columns: {
        orderNo: 'No',
        date: 'Date',
        action: 'Action',
        amount: 'Information',
        executor: 'Executor',
      },
    },
  },
  btn: {
    viewDetail: 'View Detail',
  },

  // search
  search: {
    searchTrain: 'BOOK TRAIN',
    departureDate: 'Departure date',
    returnDate: 'Return date',
    search: 'Search',
    selectDate: 'Select date (*)',
    loadingData: 'Loading data',
    searchFare: 'Search ticket fares',

    result: {
      departureDate: 'Departure',
      returnDate: 'Return',
      departureStation: 'Departure',
      arrivalStation: 'Arrival',

      notHavePrice: 'Not have price',

      // index
      searching: 'Searching...',
      noTrainFound: 'No train found for this journey!',
      clearAllTickets: 'Clear all tickets',
      clearSelectedTickets: 'Clear all selected tickets',
      enterDiscountCode: 'Enter discount code',
      apply: 'Apply',
      totalAmount: 'Total amount',
      termsAndConditions: 'I have carefully read and agree to all online ticket purchase regulations, promotional programs of Airdata, and take responsibility for the authenticity of the provided information.',
      goBack: 'Go back',
      bookTicket: 'Book ticket',
      note: 'Note',
      pleaseSearchAgain: 'Please search for the journey again!',
      bookingSuccessful: 'Train ticket booked successfully!',
      bookingError: 'Ticket booking error!',
      selectTicketAndEnterInfo: 'Please select a ticket and enter information!',
      selectReturnTicketAndEnterInfo: 'Please select a return ticket and enter information!',
      ticketExpired: 'Please remove expired tickets',
      enterPassengerInfo: 'Please enter all passenger information!',
      acceptTermsAndConditions: 'Please confirm acceptance of terms and ticket booking conditions!',
      selectTicket: 'Please select a ticket!',
      confirmInfo: 'Confirm information',

      // -
      outbound: 'Outbound:',
      inbound: 'Inbound:',
      viewPromotions: 'Promotions',
      viewFareList: 'Fare lists',
      departureTime: 'Departure time',
      arrivalTime: 'Arrival time',
      booked: 'Booked',
      seat: 'seat',
      available: 'Available',
      trainPromotions: 'Train promotions',
      noPromotions: 'No promotions',
      searchError: 'Train journey information search error',

      //
      carriageNumber: 'Carriage number',
      pleaseSelectCarriage: 'Please select a carriage!',
      availableSeats: 'Available seats',
      price: 'Price',
      priceNote: '(Excludes promotions, service fees)',
      selectedSeat: 'Selected seat',
      noPriceSeat: 'Seat without price',
      soldSeat: 'Sold',

      // paxInfo
      ticketCartInfo: 'Ticket cart information',
      fillCompleteInfo: 'Please fill in complete information including: Full name and ID, passport, driver’s license or',
      fillCompleteInfo2: 'date of birth if a child',
      fillCompleteInfo3: 'student ID if a student.',
      or: 'or',
      noTicketSelected: 'No ticket selected',
      passenger: 'Passenger',
      passengerName: 'Passenger name',
      idPassportLicense: 'ID / Passport / Driver’s license',
      roundTripOutbound: 'Round-trip outbound',
      roundTripInbound: 'Round-trip inbound',
      oneWayOutbound: 'One-way outbound',
      oneWayInbound: 'One-way inbound',
      ticketPrice: 'Ticket price:',
      serviceFee: 'Service fee',
      discountEligible: 'Discount eligible:',
      promoNote: 'Note: This is a promotional ticket. Please read carefully',

      fullName: 'Full name',
      idOrPassport: 'ID / Passport number',

      unaccompaniedChild: 'Unaccompanied child',
      unaccompaniedChildNotice: 'According to VNR regulations, children are not allowed to travel without an accompanying adult. Please enter the accompanying adult’s ticket number to continue.',
      adultTicketNumber: 'Adult ticket number',
      childRoundTripNotice: 'For round-trip journeys of unaccompanied children, please search and book tickets for each one-way leg.',
      confirm: 'Confirm',

      dob: 'Date of birth',
      noTicketForUnder6: 'Children under 6 years do not need to buy a ticket.',
      childDiscount: 'Children aged 6 to 10 are eligible for child tickets.',

      seniorDiscount: 'Elderly (aged 60 and above) receive a discount according to VNR regulations.',
      invalidDiscountAge: 'Not the correct age for discount eligibility!',
      childUnder6NoTicket: 'Children under 6 do not need a ticket if sharing a seat with an accompanying adult. Do you want to continue buying a separate ticket for this child?',
      feeService: 'Fee Service',
      compartment: 'Comp',

      columns: {
        STT: 'No',

        seatInfo: 'Ticket info',
        ticketPrice: 'Price',
        discountPassenger: 'Discount',
        discountPromotion: 'Promotions',
        originalPrice: 'Original prices',
        servicesPrice: 'Services prices',
        promotionalDiscounts: 'Promotional discounts',
        discountService: 'Discount service',
        addonsDetails: 'Addons services',
        insurancePrice: 'Insurance',
        totalPrice: 'Amount to be paid',

        seatName: 'Seat name',
        seatCode: 'Seat code',
        seatGroupCode: 'Code group - seat',
        seatAvailable: 'Seat available',
        ticketSelect: 'Ticket select',
        seatGroupName: 'Seat group name',
      },

      cart: {
        ticketCart: 'Ticket cart',
        noTicketSelected: 'No ticket selected',
        coach: 'Coach',
        seat: 'Seat',
      },
    },
  },

  // common
  bookingCode: 'Booking code',
  coach: 'Coach',
  seatNumber: 'Seat Number',
  fullName: 'Full name',
  phone: 'Phone',
  email: 'Email',
  CCCD: 'CCCD',
  phoneNumber: 'Phone number',
  lookUp: 'Look up',
  bookerInfo: 'Booker information',
  note: {
    carriageSelected: 'Carriage selected',
    carriageAvailable: 'Carriage available',
    carriageUnavailable: 'Carriage unavailable',
    selected: 'Selected',
    available: 'Available',
    unavailable: 'Unavailable',
  },
  msg: {
    retrieveError: 'Retrieve booking error',
  },
}
