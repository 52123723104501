import { env } from '@/libs/env'

export default {
  reservation: 'Reservation',
  bookingCode: 'Booking code',
  status: 'Status',
  bookingInvoiceCode: 'Booking invoice code',
  rebook: 'Rebook',
  fullname: 'Contact',
  itinerary: 'Itinerary',
  createdAt: 'Created at',
  action: 'Action',
  note: 'Note',
  index: '#',
  classBooking: 'Class booking',
  availability: 'Availability',
  priceTemp: 'Temporary price',
  result: 'Result',
  continue: 'Continue',
  information: 'Information',
  qrcode: 'QR Information',
  serviceName: 'Service name',
  priceToPay: 'Price payment',
  insurancePrice: 'Insurance price',
  ip: 'IP',

  'startDate-endDate': 'Start date - End date',
  flightDate: 'Flight Date',
  flightTime: 'Departure Time',
  flightTo: 'Flight to',
  arrivalTime: 'Arrival Time',
  bookDate: 'Book date',
  brand: 'Brand',
  airline: 'Airline',

  editNote: 'Edit note',
  notePlaceholder: 'Input your note',
  payment: 'Payment',
  exportTicket: 'Ticket',
  exportSms: 'Message',
  bookingDetail: 'Booking details',
  contact: 'Contact',
  addOn: 'Add-ons Details',
  baggage: 'Baggage',
  seat: 'Seat',
  seatNum: 'Seat number',
  otherSsr: 'Other services',
  fare_basic: 'Fare basis code',
  paxType: 'Pax type',
  bookingClass: 'Booking class',
  totalPrice: 'Total price',

  titleOptions: {
    ADULT: {
      MR: 'MR',
      MRS: 'MRS',
      MS: 'MS',
      MSTR: 'MSTR',
      MISS: 'MISS',
    },
    CHILD: {
      MSTR: 'MSTR',
      MISS: 'MISS',
      CHD: 'CHILD',
      MR: 'MR',
      MRS: 'MRS',
      MS: 'MS',
    },
    INFANT: {
      MSTR: 'MSTR',
      MISS: 'MISS',
      MR: 'MR',
      MRS: 'MRS',
      MS: 'MS',
    },
  },

  genderOptions: {
    ADULT: {
      MALE: 'Male',
      FEMALE: 'Female',
    },
    CHILD: {
      MALE: 'Male',
      FEMALE: 'Female',
    },
    INFANT: {
      MALE: 'Male',
      FEMALE: 'Female',
    },
  },

  historyActions: {
    columns: {
      orderNo: 'No.',
      date: 'Date',
      action: 'Action',
      amount: 'Information',
      executor: 'Executor',
      newValue: 'New value',
      oldValue: 'Old value',
      description: 'Description',
      chargeTime: 'Charge time',
      segments: 'Segments',
      paxId: 'Passenger',
      tax: 'Tax',
      netFare: 'Net Fare',
      total: 'Total',
      code: 'Code',
      totalRefund: 'Total refund',
      penalty: 'penalty',
      passenger: 'Pasenger',
      ticketNumber: 'Ticket number',
      price: 'Price',
    },
    title: {
      general: 'Action history',
      payment: 'Payments history',
      fromSource: 'Audit from source',
      charges: 'Charges detail',
      all: 'All',
    },
    CREATE_BOOKING: 'Create booking',
    CREATE_AND_PAYMENT_BOOKING: 'Create & payment booking',
    PRE_PAYMENT_BOOKING: 'Perform payment',
    PAYMENT_BOOKING: 'Payment booking successfully',
    CANCEL_BOOKING: 'Cancel booking',
    MODIFY_ADD_FLIGHT: 'Add flight',
    MODIFY_CHANGE_FLIGHT: 'Change flight',
    MODIFY_ANCILLARY: 'Modify add-ons',
    MODIFY_RESERVE_SEAT: 'Change seat',
    MODIFY_PAX_INFO: 'Modify passenger info',
    PAYMENT_FOR_ANCILLARY: 'Payment for add-ons',
    CALCULATE_PRICE: 'Calculate price',
    SPLIT_BOOKING: 'Split booking',
    CREATE_BOOKING_SPLIT: 'Created from split booking',
    VIEW_BOOKING: 'View booking',
    CREATE_BOOKING_OUT_SIDE: 'Create booking out side',
    SET_AUTO_ISSUE: 'Set auto issue',
    SET_AUTO_ISSUE_AND_CANCEL: 'Set/Cancel auto issue',
    SEND_EMAIL: 'Send email',
    VOID_TICKET: 'VOID Ticket',
    MODIFY_DOCUMENT: 'Modify document',
    MODIFY_REMARK: 'Modify remark',
    REFUND_TICKET: 'Refund ticket',
    EXCHANGE_TICKET: 'Exchange ticket',
    SHORTEN_TICKET: 'Shorten ticket',
    // CONFIRM_ALL_SEGMENT: 'CONFIRM_ALL_SEGMENT',
    // CMD_MODIFY_REMARK: 'CMD_MODIFY_REMARK',
    // CMD_OSI: 'CMD_OSI',
    CMD_QUEUE: 'CMD Queue',
    NOTE: 'Edit note',
    SPECIAL_SERVICE: 'Book special services',
    MODIFY_SPECIAL_SERVICE: 'Modify special services',
    MODIFY_TICKET_DETAILS: 'Modify ticket',
    CONFIRM_SEGMENTS: 'Confirm segments',
    MODIFY_FFTV: 'Edit membership card',
    SET_AUTO_ISSUE_TIME: 'Set auto issue time',
    MODIFY_OSI: 'Modify OSI',
    CANCEL_SEATS: 'Cancel seats',
    SET_LOW_FARE_TRACKING: 'Set low fare tracking',
    CANCEL_LOW_FARE_TRACKING: 'Cancel low fare tracking',
    UPDATE_DISCOUNT: 'Update discount',
    MODIFY_PAYMENT_ANCILLARY: 'Payment ancillaries',
    MODIFY_PAYMENT_RESERVE_SEAT: 'Payment reserve seats',
    STOP_PAYMENT_REMINDER: 'Change stop payment reminder',
    ACTIVE: 'Show booking',
    DEACTIVE: 'Hide booking',
    CALCULATE_PRICE_BARGAIN_FINDER: 'Calculate price for bargain finder',
    CREATE_INSURANCE_DRAFT: 'Create insurance draft',
    CREATE_COVER_NOTE: 'Create cover note',
    EDIT_COVER_NOTE: 'Edit cover note',
    CREATE_POLICY: 'Payment insurance',
    CANCEL_INSURANCE: 'Cancel insurance',
    REFUND_INSURANCE: 'Refund insurance',
  },

  recalculatePriceFlight: 'Recalculate Price',
  cancelFlight: env.isCancelItineraryTextButton ? 'Cancel trip' : 'Cancel flight',
  addFlight: 'Add flight',
  addQuickFlight: 'Quick add flight',
  'addFlight/DS': 'Add flight/DS',
  changeFlight: 'Change flight',
  changeItinerary: 'Change flight',
  changeFlights: 'Change flight',
  confirmSC: 'SC confirm',
  separateBooking: 'Separate Booking',
  addInfant: 'Add infant',
  recalculatePrice: {
    title: 'Recalculate price',
    chooseQuoteForPayment: 'Choose quote for payment',
    withDOB: 'Recalculate price with DOB',
    paxInformation: 'Passenger information',
    itinerariesInformation: 'Itỉneraries',
    'The system will automatically recalculate the price for all passengers on the booking': 'The system will automatically recalculate the price for all passengers on the booking',
  },

  no: 'No.',
  passenger: 'Passenger',
  paxs: 'Passenger type',
  passengerDetails: 'Passenger details',
  flightAndBaggage: 'Flight / Baggage',
  flight: 'Flight',
  Flight: 'Flight',
  price: 'Price',
  priceNet: 'NET price',
  taxAndOtherFees: 'Tax/Other fees',
  total: 'Total',
  totalPirce: 'Total price',
  pricePaidToSupplier: 'Price paid to supplier',
  totalAmountToPay: 'Total amount to pay',
  totalAmountNeedToPay: 'Total amount need to pay',
  itineraryChangeFare: 'Itinerary change fee',
  discountAmount: 'Discount',
  totalAmountPaid: 'Total amount paid',
  aircraft: 'Aircraft',
  priceDetails: {
    header: 'Price details',
    title: 'Price details',
    caution: {
      title: 'CAUTION',
      content1: 'Please carefully check the information Passenger name - Itinerary - Flight date/time - Ticket price - Validity of Passport/Visa for entry & transit (if necessary).',
      content2: 'Prices, taxes and exchange rates may change at the time of ticket issuance (Especially for SOTO tickets departing from outside Vietnam).',
      content3: 'If you have any questions or errors, please report them to Booker.',
    },
    titleTable: {
      paxType: 'Passenger type',
      flight: 'Flight',
      amount: 'Amount',
      fareBasisCode: 'Fare basis code',
      expirationTime: 'Expiration time',
      lastDateToPurchase: 'Last date to purchase',
      tax: 'Tax',
      fare: 'Fare',
      total: 'Total',
      netFare: 'Fare',
      feeService: 'Service Fee',
      agencyFee: 'Fee',
    },
    pleaseRecalculatePrice: 'Please recalculate price to payment!',
  },
  paymentDetails: 'Payment details',
  sellingPriceDetails: {
    title: 'Selling Price Information',
    reSetServiceFee: 'Set service fee',
    tableColumnsPaxFareInfoTicket: {
      passenger: 'Passenger',
      netPrice: 'Net fare',
      tax: 'Taxes and fees',
      feeService: 'Fee service',
      total: 'Total',
      agencyFee: 'Fee',
    },
  },
  back: 'Back',
  detail: 'Detail',
  confirm: 'Confirm',
  save: 'Save',

  flightHasBeenCancelled: 'This reservation not have any itineraries.',
  selectItineraryToCancel: 'Select the itinerary you want to cancel:',
  selectItineraryToRefund: 'Select the itinerary you want to refund:',
  selectItineraryToRecalculatePrice: 'Select the itinerary you want to recalculate price:',

  cancelFlightError: 'Error cancel flight.',
  cancelFlightSuccessful: 'Cancel flight successful.',

  tax: 'Tax',
  fees: 'Other Fees',
  Passenger: 'Passenger',
  Price: 'Price',
  Total: 'Total',

  refundFee: 'Refund fee',
  refundAmount: 'Refund amount after cancel flight',
  chargeAmount: 'Total payment',
  refundFlightAmount: 'Total payment',
  error: 'Error: Cannot cancel this flight',
  errorChangeFlight: 'Error: Cannot change this flight',
  loading: 'Loading',
  close: 'Close',
  submit: 'Submit',
  confirmCancelFlight: 'Are you sure to cancel the flight?',
  confirmRefundFlight: 'Are you sure to refund the flight?',
  selectAll: 'Select all',
  unselectAll: 'Unselect all',

  separateBookingText: 'Select passengers to separate booking',
  separateBookingSuccessful: 'Separate booking successful.',
  separateBookingError: 'Error separate booking',
  confirmSeparateBooking: 'Are you sure to separate booking?',

  phoneNumber: 'Phone number(s)',
  cannotChangePhone: 'Phone number change is not supported!',
  cannotChangeEmail: 'Email change is not supported!',
  company: 'Company',
  addMorePhone: 'Add more phone',
  addMoreEmail: 'Add more email',
  placeHolderCompany: 'Company name',

  Type: 'Type',
  Gender: 'Gender',
  Lastname: 'Last name',
  Firstname: 'First name',
  Title: 'Honorific',
  DoB: 'Date of birth',
  Action: 'Action',
  edit: 'Edit',

  findBookingByPnrCode: 'Search by PNR',
  cancel: 'Cancel',
  find: 'Find',

  inputPnrPlaceholder: 'Input PNR',
  inputSourcePlaceholder: 'Input source',
  source: 'Source',
  pnrCode: 'PNR',
  cannotFindBooking: 'Booking not found',
  selectedExtraBaggage: 'Select Extra Baggage',
  selectSeat: 'Select seat',
  selectOtherServices: 'Select other services',
  Available: 'Available',
  Unavailable: 'Unavailable',

  searchFlight: 'Search Flight',
  selectTrip: 'Select trip',
  confirmChangeFlight: 'Are you sure to change flight?',
  confirmAddFlight: 'Are you sure to add flight?',
  changeFlightsSuccessful: 'Change flights successful',
  changeFlightsError: 'Error change flights',
  editInfoPaxList: 'Passenger details',
  confirmChange: 'Confirm change',
  confirmInfoChange: 'Confirm info change',
  changeInfoPassenger: 'Change info',
  changeInfoSuccessful: 'Change info successful',
  changeInfoError: 'Error change info',
  ADULT: 'Adult',
  CHILD: 'Child',
  INFANT: 'Infant',
  errTitle: 'Error',

  paymentAncillary: 'Ancillary payment',
  flightsDetails: {
    title: 'Flights details',
    status: 'Status',
    bookingClass: 'Booking class',
    airCraft: 'Aircraft',
    distance: 'Distance (mileage)',
  },
  dkve: 'Fare rules',
  dkve1A: 'Fare rules 1A',
  timeHold: 'Time hold',
  autoIssueTime: 'Auto issue time',
  contactInfo: 'Contact information',

  message: {
    'Booking not found': 'Booking not found',
    activeReservationSuccess: 'Show booking successful',
    activeReservationError: 'Error show booking',
    deactiveReservationSuccess: 'Hide booking successful',
    deactiveReservationError: 'Error hide booking',
    banBookingSuccess: 'Ban booking successful',
    unbanBookingSuccess: 'Unban booking successful',
    banBookingError: 'Error ban booking',
    submitBookingSuccess: 'Submit booking successful',
    submitBookingError: 'Error submit booking',
    sendSubmitBookingMailSuccess: 'Send submit booking mail successful',
    sendSubmitBookingMailError: 'Error send submit booking mail',
  },

  placeholderSelect: 'Please select',
  placeholderInput: 'Please input',
  phFirstName: 'Please input first name',
  phLastName: 'Please input last name',
  selectAdult: 'Choose an adult to accompany',
  noteAddInfant:
    'The baby must be between 0-2 years old from the date of departure',
  noteAddInfant_2: 'An ADULT is required to be able to add a INFANT.',
  cantAddInfant: 'INFANT cannot be added into this booking code.',

  paymentOverLimit:
    'Payment cannot be processed as the agency <code class="code-agency">{agencyCode}</code> has exceeded the debt limit.',
  paymentWarning:
    'The current outstanding balance of agency <code class="code-agency">{agencyCode}</code> has almost reached the limit of total outstanding balance.',
  isWarningLimitPercent:
    'Agency balance of agency <code class="code-agency">{agencyCode}</code> exceeds {percent}% of the payment limit.',
  isOverLimitParent:
    'Payment cannot be made because the HIGHER LEVEL AGENCY <code class="code-agency">{agencyCode}</code> outstanding balance has exceeded the amount owed limit.',
  issueWarning:
    'If agency balance exceeds the total outstanding limit, tickets will not be issued.',
  agencyName: 'Agency name',
  book: 'book',
  pay: 'pay',
  balanceLimit: 'Current balance', // / Total debt limit
  limitCredit: 'Limit credit',
  issueTicketPrice: 'Issue ticket price',
  ticketPrice: 'Ticket price',
  discount: 'Discount (temporary)',
  discountAfter: 'Discount (postpaid)',
  balanceAfterPayment: 'Balance after payment',
  'Check latest promotion': 'Check latest promotion',
  'Calculate promotion': 'Calculate promotion',
  'Promotion is no longer available, please recalculate the promotion': 'Promotion is no longer available, please recalculate the promotion',
  'Promotion has updated, please recalculate the promotion': 'Promotion has updated, please recalculate the promotion',
  'Latest promotion has applied': 'Latest promotion has applied',
  calcPriceForChangeFlight:
    'Flight calculation in progress, please wait a moment',
  confirmAddToFlight: 'Confirm add flight',
  oldFlight: 'Old flight',
  newFlight: 'New flight',
  noNote: 'No note',
  modifyDocuments: 'Modify Passport Info',
  modifyDocumentsVJ: 'Modify CCCD/PP',
  ticketType: 'Ticket type',
  eTicket: 'Ticket number',
  amount: 'Amount',
  fare: 'Fare',
  changeTicket: 'Change ticket',
  refundTicket: 'Refund ticket',
  refundTicketVJ: 'Refund ticket identification',
  allowRefund: 'Allow refund',
  disallowRefund: 'Disallow refund',
  identification: 'identification',
  eticketInfo: 'eTicket info',
  noTicketNum: 'No ticket number',
  eticketList: 'eTicket list',
  addTicket: 'Add ticket',
  addTicketToBooking: 'Add ticket to booking',
  agencyInfo: 'Agency contact information',
  customerInfo: 'Customer contact information',
  copyEticketSuccess: 'Copy ticket success',
  copyEticketError: 'Copy ticket error',

  voidTicket: {
    title: 'Void ticket',
    titleExternal: 'Void external ticket',
    paymentTime: 'Payment time',
    selectTicketToVoid: 'Select tickets you want to void',
    QH_void1: 'This action will VOID all tickets already included in this ticket',
    QH_void2: 'VOID tickets only apply when the ticket is void ON the departure date and the issuance date is DIFFERENT from the flight date',
    VN_void1: 'Regulations on VOID tickets issued on the day of flight',
    VN_void2: 'Void for domestic journeys {time} hour before flight time',
    VN_void: 'Ticket void deadline: before 11:00 p.m. on ticket issuance date, 03 hours before departure time (for Domestic tickets), 06 hours before departure time (for International tickets).',
    lockVoid: 'cannot void ticket.',
    caution: 'If you do not have need to fly, please cancel your reservation.',
  },

  noBaggage: 'No baggage in this segment',
  contactBookerBaggage: 'Please contact Booker for ticket information',
  noOtherServices: 'No other services in this segment',

  payments: {
    confirmReCalcPrice: 'Click to confirm to recalculate price',
    checkCommissionCantVoid1: 'System does not support payment with this booking.',
    checkCommissionCantVoid2: 'Please contact booker for help!',
    isRequiredPassport: "Please enter the passenger's passport before payment!",
    isNotEqualLengthTrip: 'The number of itineraries in the booking code and in the price quote is different. Please check carefully, especially if your agent has added an itinerary and has not recalculated the price!',
    pleaseRecalculatePrice: 'Please recalculate price to payment!',
    emptyItineraries: 'Booking no longer has itineraries to pay for',
    confirmPaymentAncillary: 'Confirm payment ancillary services?',
    paymentWithDob: 'Issue a ticket with date of birth.',
    paymentWithDobNote: 'Do not select this function if you have not performed "Recalculate price with date of birth" before.',
  },

  // for booking sms
  copy: 'Copy',
  code: 'Booking Code',
  route: 'Route',
  at: 'At',
  date: 'date',
  baggageWithFee: 'Baggage with fee',
  totalAmount: 'Total amount',
  sms: {
    combinate: 'Combinate SMS',
    note: 'Please double check all booking information is correct before sending to customer.',
    note1Domestic: 'Bring your valid ID card',
    note1International: 'Bring your Passport',
    note2Domestic: 'Be there 90 minutes in advance',
    note2International: 'Be there 180 minutes in advance',
    vnWithAccent: 'Vietnamese with accents',
    vnWithAccentShorten: 'Shorten',
    vnNoAccent: 'Vietnamese without accents',
    en: 'English',
    copySuccess: 'Copied booking content to clipboard successfully!',
    copyFail: 'Copied booking content to clipboard failed!',
  },
  retain: 'Save price calculation',
  full_name: 'Full name',
  pax_type: 'Passenger type',
  pax_id: 'Pax ID',
  accountCode: 'Account code',
  ADT: 'Adult',
  CNN: 'Child',
  CHD: 'Child',
  INF: 'Infant',

  meals: 'Meals',
  errGetEticket: 'Error get eTicket.',
  confirmExchange: 'Are you sure to exchange?',
  chooseSegment: 'Choose the segment to exchange',
  confirmRefundTicket: 'Are you sure to refund?',
  shortenBooking: {
    titleBooking: 'Shorten booking',
    titleBooking1A: 'Shorten booking 1A',
    titleHistory: 'History',
    titleBaggages: 'Shorten baggages',
    titleFareRules: 'Shorten fare rules',

    copyBooking: 'Copy shorten booking',
    copyBooking1A: 'Copy shorten booking 1A',
    copyHistory: 'Copy history',
    copyBaggages: 'Copy shorten baggages',
    copyFareRules: 'Copy shorten fare rules',

    reloadButtonTooltip: 'Reload',
  },
  history: {
    title: 'HISTORY',
    reloadButtonTooltip: 'Reload HISTORY',
  },
  remarks: {
    title: 'Remarks',
    send: 'Send remarks',
  },

  sendQueues: {
    title: 'Send Queues',
    queueCode: 'Queue code',
    queueText: 'Remark',
    placeholderQueueCode: 'Select queue code',
    placeholderQueueText: 'Enter remark',
    placeholderChangeName: 'Enter passenger\'s name',
    changeName: 'Change passenger\'s name',
    chooseQueue: 'Select queue',
    oldPassenger: 'Select passenger',
    chooseSegmentToSC: 'Select segment SC',
    noSegmentTK: 'There are no TK segments.',
  },

  osi: {
    title: 'Send OSI',
    text: 'Text',
    delete: {
      title: 'Delete OSI',
      osiList: 'OSI list',
    },
    chooseOsiCode: 'OSI code',
    placeholderRemark: 'Example: DAI BIEU QUOC HOI',
  },

  confirmSegments: {
    title: 'Confirm Segments',
    confirm: 'Are you sure confirm segments ?',
  },

  haveArunk: 'Have an arunk',
  notiFromSystem: 'Notifications from airline',

  ALL: 'All',
  HOLD: 'Hold',
  PAID: 'Paid',
  PARTIALLY_PAID: 'Partially paid',
  PARTIALLY_PAID_ONLY: 'Partially Fee',
  UNPAID: 'Unpaid',
  CANCELLED: 'Cancelled',
  CANCEL: 'Cancel',
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  DISAPPEAR: 'Disappear',
  DRAFT: 'Draft',

  needPayment: 'Need payment',
  thisFlightCancelled: 'This flight has been cancelled.',
  ancillaries: 'Add-ons service details',
  services: 'Services',
  noAddons: 'No add-ons service on the itinerary.',
  addBaggage: 'Add baggage',
  noSeatMapData: 'No seat map data!',
  addSeat: 'Add seat',
  detailSeat: 'Detail seat',
  addOtherServices: 'Add other services',
  addServices: 'Add services',
  segment: 'Flight segment',
  amountRefunded: 'Amount refunded after refund ticket',
  loadingData: 'Loading data...',
  bargainFinder: 'Automatically calculate the cheapest price',
  booker: 'Booker',
  totalPriceItinerary: 'Total price',
  iAmConfirm: 'I understand and agree',

  specialServices: {
    title: 'Special services',
    itineraries: {
      title: 'Select itineraries',
    },
    passengers: {
      title: 'Select passengers',
    },
    ss: {
      title: 'Select special services',
      freeText: 'Free text',
    },
    book: 'Book special service',
  },

  customerDetails: {
    title: 'Customer Details',
    name: 'Fullname',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    domain: 'Domain',
  },

  bookingInvoice: {
    title: 'Booking Invoice',
    companyName: 'Company Name',
    address: 'Address',
    cityName: 'City Name',
    taxCode: 'Tax Code',
    receiver: 'Receiver',
    email: 'Email',
  },

  bookerDetails: {
    title: 'Agency Details',
    rtlTitle: 'User Details',
    name: 'Fullname',
    phone: 'Phone',
    email: 'Email',
    agency: 'Agency',
    parentAgency: 'Parent Agency',
  },
  removeCancelFee: 'Contact the call-center to remove the cancel fee before paying',
  net: 'Net',

  showCalculatePrice: {
    columns: {
      key: 'key',
      bookingInfos: 'Booking infos',
      appliedPassengers: 'Applied passengers',
      basePrice: 'Base price',
      totalTaxes: 'Total taxes',
      totalPrice: 'Total price',
    },
    savePrice: 'Save price calculation',
    title: 'Show calculate price',
    headerInformationTitle: 'Header information',
  },

  agency: 'Agency',
  customer: 'Customer',
  chooseAgencyOrCustomerTitle: 'Select agency or customer to assign to booking',
  birthday: 'Birthday',
  quantity: 'Quantity',
  viewEticket: 'View eticket number',
  viewEticket1S: 'View eticket 1S',
  viewEticket1G: 'View eticket 1G',
  viewEticket_VN1A_QH_1G: 'View eticket VN1A, 1G, QH',
  languageCode: {
    VIETNAMESE: 'Vietnamese',
    ENGLISH: 'English',
    FRENCH: 'French',
    SPANISH: 'Spanish',
    PORTUGUESE: 'Portuguese',
    ITALIAN: 'Italian',
    GERMAN: 'German',
    SWEDISH: 'Swedish',
    GREEK: 'Greek',
    SIMPLIFIED_CHINESE: 'Simplified Chinese (zh)',
    TRADITIONAL_CHINESE: 'Traditional Chinese (zh-Hant)',
    JAPANESE: 'Japanese',
    KOREAN: 'Korean',
    THAI: 'Thai',
    RUSSIAN: 'Russian',
    POLISH: 'Polish',
    TAIWAN: 'Taiwan',
  },
  sendEmail: {
    title: 'Send Email',
    chooseLanguage: 'Select the language for sent email',
    sendEmailSuccess: 'Send email successfully',
    sendEmailError: 'Error send email',
  },
  ban: {
    title: 'Ban IP',
    unban: 'Unban IP',
  },
  f1PaymentForF2: 'This agency does not have enough balance to payment. Are you sure want to pay this agency?',

  paymentReminder: {
    title: 'Ticket payment reminder',
    confirm: '{statusChange} ticket export reminder?',
    activate: 'Ticket export reminder activated!',
    deactivate: 'Ticket export reminder deactivated!',
  },

  Passport: 'Passport',
  ppOrCccdNum: 'CCCD/PP number',
  ppOrCccd: 'CCCD/PP',
  Accompanying_infant: 'Accompanying infant: ',
  Accompanying_adult: 'Accompanying adult: ',

  lowFare: {
    lowfareTracking: 'Low fare tracking',
    selectTrip: 'Please select an itinerary to take',
    flightStatus: 'Flight status',
    noItineraries: 'No itinerary found!',
    rangeFare: 'Enter the price range',
    lowestFare: 'Enter the lowest price:',
    highestFare: 'Enter the highest price',
    required: 'required',
    noteGetPrice: 'The system will monitor tickets lower or equal to this price',
    noItiSelect: 'No itinerary selected yet!',
    turnOn: 'Turn on set low fare tracking',
    infoLowFare: 'Tracking fare information',
    processing: 'Processing...',
    success: 'Success',
    turnOff: 'Turn off',
    expired: 'Expired',
    notTurnOn: 'The low fare tracking has not been turned on yet',
    note: 'NOTE:',
    note1: '- Please cancel the old code after successfully checking the ticket as soon as possible (remember to check the price and ticket conditions), all duplicate codes can be canceled before the warning time. BGT is not responsible for cases of code cancellation due to dupe.',
    note2: '- The Low fare tracking feature currently only supports VNA, VU, tickets in reservation status (HOLD).',
    note3: '- Support creating partial reservations in the passenger list in case the number of available seats on the ticket found is less than the number of passengers in the current reservation.',
    option: 'Optional',
    deadlineTask: 'Set a deadline for low fare tracking',
    setTimeBefore: 'Please book your time in advance:',
    timeEndTracking: 'End time:',
    lowfareType: 'Low-fare action type:',
  },
  hideBooking: 'Hide booking',
  showBooking: 'Show booking',
  hideBookingConfirm: 'Are you sure {type} the booking code',
  showHideBooking: 'Show/hide booking',
  Hide: 'Hide',
  Show: 'Show',
  lockBalance: 'Lock balance',
  All: 'All',
  BBClub: 'Bamboo Club',
  BSV: 'Lotusmiles',
  membershipCard: 'Membership card',
  successCreateTTV: 'The successfully created membership card number will be sent to your email',
  errCreateMembershipCardAirline: 'Error creating airline membership card',
  fareBasisCode: 'Fare basis code',
  newMembershipCreated: 'Newly created {airline} card number:',
  dontHaveMembershipCardYet: "Don't have a membership card yet?",
  createNew: 'Create new',
  cardNumber: 'Card number',
  findBookingVJ: {
    title: 'Find booking Vietjet',
    headerFilter: 'Find booking Vietjet',
    headerResult: 'Result',
    departureAirport: 'Departure airport',
    departureDateFrom: 'Departure date from',
    departureDateTo: 'Departure date to',
    createDateFrom: 'Date book from',
    createDateTo: 'Date book to',
    findAirportPleaseSearch: 'Please enter airport name or code',
    no: 'No',
    bookingCode: 'PNR',
    passenger: 'Owner',
    itineraries: 'Itineraries',
    status: 'Status',
    dateCreate: 'Date create',
    key: 'Key',
  },
  placeHolderSearchText: 'Searching passenger name, booker name,... ',
  placeHolderFindPNR: 'Searching PNR in system',

  bookingStatistic: {
    title: 'Hold booking statistic',
    startFlightDate: 'Start flight date',
    endFlightDate: 'End flight date',
    source: 'Source',
    no: 'No.',
    itineraries: 'Itineraries',
    flightNumber: 'Flight Number',
    bookingCode: 'Booking Code',
    detail: '',
    flightDate: 'Flight Date',
    totalBooking: 'Total booking',
    paxLists: 'Passengers',
    totalPassenger: 'Total passengers',
    findAll: 'Find all',
  },
  lowFareTracking: {
    title: 'Low fare tracking statistic',
    startDate: 'Start date',
    endDate: 'End date',
    source: 'Source',
    no: 'No.',
    flightNumber: 'Flight Number',
    bookingCode: 'Booking Code',
    createdAt: 'Created At',
    passengers: 'Passengers',
    flight: 'Flight',
    delete: 'Delete',
    all: 'All',
    trackingSuccess: 'Tracking Success',
    tracking: 'Tracking',
    trackingNotSuccess: 'Tracking Not Success',
    lowFareTrackingCode: 'Low Fare Tracking Code',
    lowFareTrackingStatus: 'Low Fare Tracking Status',
    success: 'Success',
    processing: 'Processing',
    cancelled: 'Cancelled',
    booker: 'Booker',
  },
  pastDayBooking: {
    title: 'Past day booking statistic',
  },

  assignBooking: {
    title: 'Assign booking',
  },
  specifiedPassengers: 'Ngày sinh khách',
  specifiedPassengersPlaceholder: 'Please enter DOB (15/04/1999, 15-04-1999)',
  filters: {
    agencyCode: 'Agency',
    createdBy: 'Enter employee code',
  },
  linkFareRule: 'Link fare rule',
  addBSV: 'Add BSV',
  addTTV: 'Add membership card',
  editCtcmCtceFor1g: {
    title: 'Add/Remove CTCE/CTCM',
    addTitle: 'Add CTCE/CTCM',
    removeTitle: 'Remove CTCE/CTCM',
    emailPlaceholder: 'Please enter email',
    phonePlaceholder: 'Please enter phone',
    contentSuccess: 'Add/Remove CTCM/CTCE successfully',
    contentError: 'Error Add/Remove CTCM/CTCE',
  },
  sendSubmitBooking: 'Send Submit Booking',
  submitBooking: 'Submit Booking',
  sendSubmitBookingMail: 'Send submit booking mail',
  submitBookingInfo: 'Book/reserve seats according to draft information',
  passengerType: 'paxType',
  paymentStatus: 'Payment status',
  iAgree: 'I understand and agree',
  hideFeature: 'Hide feature',
  showFeature: 'Show feature',
  otherServiceProduct: 'Other products',
  roundtripTicket: 'Roundtrip booking',
  splitIntineraryTicket: 'Split itinerary booking',
  groupTicket: 'Group booking',
  splitPassengerTicket: 'Split passenger booking',
  benefit: 'Benefit',
  removeInfant: 'Remove Infant',
  'Passengers who already have a membership card, please delete the old card if you want to change!': 'Passengers who already have a membership card, please delete the old card if you want to change!',
  'Error in retrieving information of adults accompanying the infant!': 'Error in retrieving information of adults accompanying the infant!',
  bookingCancelled: 'Booking code has been cancelled',
  'No notification from the system': 'No notification from the system',
  'Business lounge': 'Business lounge',
  'information & QR': 'Information & QR',
  paid: 'PAID',
  insuranceServices: 'Insurance service',
  createCoverNote: 'Create cover note',
  updateCover: 'Change',
  deleteCover: 'Delete',
  cancelCover: 'Cancel',
  refundCover: 'Refund',
  createInsurance: 'Create insurance service',
  createLounge: 'Create business lounge',
  'Please pay for tickets before purchasing additional services!': 'Please pay for tickets before purchasing additional services!',
  'No itinerary found in booking': 'No itinerary found in booking!',
  'Lounge service is available for flights originating from the city. Ho Chi Minh (SGN)!': 'Lounge service is available for flights originating from the city. Ho Chi Minh (SGN)!',
  'Insurance services can only be purchased for international itineraries originating from Vietnam!': 'Insurance services can only be purchased for international itineraries originating from Vietnam!',
  'No passengers found who can add a new insurance package!': 'No passengers found who can add a new insurance package!',
  'No passengers found!': 'No passengers found!',
  'QR code information error, please contact booker to handle!': 'QR code information error, please contact booker to handle!',
  cancelInsurance: 'Cancel insurance',
  deleteInsurance: 'Delete insurance',
  cancelInsSuccess: 'Cancel insurance success!',
  deleteInsSuccess: 'Delete insurance success!',
  cancelInsErr: 'Insurance package cancellation failed, please check the information again!',
  deleteInsErr: 'Insurance package delete failed, please check the information again!',
  'The insurance package has reached its effective date and cannot be refunded!': 'The insurance package has reached its effective date and cannot be refunded!',
  paymentInsurance: 'Pay for insurance package',
  'Confirmation of insurance package payment:': 'Confirmation of insurance package payment:',
  planPrice: 'Price',
  'Payment term': 'Payment term',
  paymentInsSuccess: 'Insurance package payment successful',
  paymentInsErr: 'Insurance package payment failed, please contact booker! ',
  domestic: 'Domestic',
  international: 'International',
  changeInsuranceItemTitle: 'Change insurance item',
  addInsuranceItemTitle: 'Add insurance',
  addInsurance: 'Add insurance',
  bookingInfos: 'Booking infos',
  'First departure time': 'First departure time',
  'Retrieving information about insurance packages': 'Retrieving information about insurance packages',
  'Error retrieving insurance package information, please contact booker to check again': 'Error retrieving insurance package information, please contact booker to check again',
  'Note: International Travel Insurance products apply to ages from 01 day old to 75 years old': 'Note: International Travel Insurance products apply to ages from 01 day old to 75 years old',
  'Passengers have not been selected yet!': 'Passengers have not been selected yet!',
  'Please select at least one passenger!': 'Please select at least one passenger!',
  'CHOOSE INSURANCE PACKAGE': 'CHOOSE INSURANCE PACKAGE',
  'INSURANCE BENEFITS': 'INSURANCE BENEFITS',
  '(Unit: USD)': '(Unit: USD)',
  'Hide discount': 'Hide discount',
  'Show discount': 'Show discount',
  'Old package': 'Old package',
  'Select package': 'Chọn gói',
  Selected: 'Selected',
  'Personal accident': 'Personal accident',
  'Medical costs': 'Medical costs',
  'Medical aid': 'Medical aid',
  'See details in the benefits table': 'See details in the benefits table',
  'Travel support': 'Travel support',
  'IPA Relief Services': 'IPA Relief Services',
  Include: 'Include',
  'Costs related to Covid-19': 'Costs related to Covid-19',
  'Up to 70 years old': 'Đến 70 tuổi',
  'Apply package': 'Apply package',
  'Maximum $50,000/person/Insured period, whichever is lower': 'Maximum $50,000/person/Insured period, whichever is lower',
  'Over 70 years old': 'Over 70 years old',
  '$5,000/person/insurance period, whichever is lower': '$5,000/person/insurance period, whichever is lower',
  'Maximum $5,000/person/term of insurance, whichever is lower': 'Tối đa $5,000/người/thời hạn bào hiểm,tùy số nào thấp hơn',
  'Create cover note': 'Create cover note',
  'Start time': 'Thời gian bắt đầu',
  'Contact phone number': 'Contact phone number',
  'Contact email': 'Contact email',
  'Customer name used': 'Tên khách hàng sử dụng',
  'Please check again and enter complete information': 'Please check again and enter complete information!',
  'Edit cover note information': 'Edit cover note information',
  'Refund of insurance package': 'Refund of insurance package',
  'Insurance package': 'Insurance package',
  Reason: 'Reason',
  'Change itinerary': 'Change itinerary',
  'Cannot apply for Visa': 'Cannot apply for Visa',
  'Other reasons': 'Other reasons',
  'Please select a return reason!': 'Please select a return reason!',
  'Only enter up to 255 characters!': 'Only enter up to 255 characters!',
  'Insurance package completed successfully': 'Insurance package completed successfully!',
  'Insurance package refund failed, please check the information again': 'Insurance package refund failed, please check the information again',
  'Insurance information': 'Insurance information',
  'Contract Code': 'Mã hợp đồng',
  'Compensation level': 'Mức bồi thường',
  Area: 'Area',
  'Please wait': 'Please wait',
  'DOWNLOAD THE DRAFT CONTRACT': 'DOWNLOAD DRAFT CONTRACT',
  'DOWNLOAD OFFICIAL CONTRACT': 'DOWNLOAD OFFICIAL CONTRACT',
  EDIT: 'EDIT',
  'The service can only be purchased 1 hour before the first departure time': 'The service can only be purchased 1 hour before the first departure time',

  lockedList: {
    title: 'Locked booking list',
    columns: {
      no: 'No.',
      bookingCode: 'Booking code',
      agPayment: 'Agency code payment',
      lockBalanceItem: 'Lock balance amount',
    },
  },

  'Warning for duplicate tickets!': 'Warning for duplicate tickets!',
  'The issued ticket has an error with the ticket number hidden. Please wait for 5-10 minutes, open PNR and check again. To avoid duplicate export, please contact the Sales/Booker department to double check the information.': 'The issued ticket has an error with the ticket number hidden. Please wait for 5-10 minutes, open PNR and check again. To avoid duplicate export, please contact the Sales/Booker department to double check the information.',

  'Payment error from the airline': 'Payment error from the airline.',
  'Please double check your booking code before trying again to avoid duplicate tickets': 'Please double check your booking code before trying again to avoid duplicate tickets.',
  'Reload booking code': 'Reload booking code',
  'Booking code has been cancelled. Unable to pay!': 'Booking code has been cancelled. Unable to pay!',
  'Please select quote to pay!': 'Please select quote to pay!',
  'Please check your credit limit for payment': 'Please check your credit limit for payment',
  'Tickets are not issued for HL itineraries, agents please check the itinerary information or contact the booker to issue tickets': 'Tickets are not issued for HL itineraries, agents please check the itinerary information or contact the booker to issue tickets!',
  'For 1A GDS bookings, please contact the booker to issue tickets': 'For 1A GDS bookings, please contact the booker to payment tickets!',
  addDocuments: {
    title: 'Add documents',
    CCCD: 'CMND/CCCD',
    passport: 'Passport',
    birthday: 'Birthday',
    'The current system only supports Passport': 'The current system only supports Passport',
    'Please enter passenger passport information': "Please enter passenger's passport information",
  },
  loading2: 'Loading...',
  'Tickets cannot be combined': 'Tickets cannot be combined',
  'No active ticket links found': 'No active ticket links found!',
  'Cannot view tickets for inactive bookings': 'Cannot view tickets for inactive bookings!',
  'No passengers can add passports': 'No passengers can add passport!',
  'No passengers can changes info': 'No passengers can changes info!',
  formatDoB: 'Định dạng ngày sinh (DD/MM/YYYY)',
  modifyPaxsInfo: 'Change passengers info',
  'Can to change': 'Can to change!',
  'Remaining time': 'Remaining time',
  'Confirmation time has expired, please try again': 'Confirmation time has expired, please try again',
  'Cannot be changed, please check the company regulations or contact the booker for processing': "Cannot be changed, please check the company's regulations or contact the booker for processing",
  'Please contact booker for support': 'Please contact Booker/Sales for support!',
  'Please check the service fee again': 'Please check the service fee again!',
  passportLessThan6MonthsBeforeFlight: 'Passport is about to expire.',
  stopAp: 'Stop',
  keepGroup: 'Keep fares group',
  airPnr: 'Air PNR',
}
