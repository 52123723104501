export default {
  alpha: 'The {_field_} field may only contain alphabetic characters',
  alpha_dash:
    'The {_field_} field may contain alpha-numeric characters as well as dashes and underscores',
  alpha_num: 'The {_field_} field may only contain alpha-numeric characters',
  alpha_spaces:
    'The {_field_} field may only contain alphabetic characters as well as spaces',
  between: 'The {_field_} field must be between {min} and {max}',
  confirmed: 'The {_field_} field confirmation not match {target}',
  digits:
    'The {_field_} field must be numeric and exactly contain {length} digits',
  dimensions: 'The {_field_} field must be {width} pixels by {height} pixels',
  double: 'The {_field_} field must be a valid decimal',
  email: 'The {_field_} field must be a valid email',
  excluded: 'The {_field_} field is not a valid value',
  ext: 'The {_field_} field is not a valid file',
  image: 'The {_field_} field must be an image',
  integer: 'The {_field_} field must be an integer',
  length: 'The {_field_} field must be {length} long',
  max: 'The {_field_} field may not be greater than {length} characters',
  max_value: 'The {_field_} field must be {max} or less',
  mimes: 'The {_field_} field must have a valid file type',
  min: 'The {_field_} field must be at least {length} characters',
  min_value: 'The {_field_} field must be {min} or more',
  numeric: 'The {_field_} field may only contain numeric characters',
  oneOf: 'The {_field_} field is not a valid value',
  regex: 'The {_field_} field format is invalid',
  required: 'The {_field_} field is required. Please enter {_field_}.',
  required_if: 'The {_field_} field is required',
  size: 'The {_field_} field size must be less than {size}KB',
  phoneNumber: 'The {_field_} field format is invalid',
  url: 'The {_field_}  is invalid',
  code: 'The {_field_} field format is invalid. {_field_} must be a 9-character string consisting of uppercase letters and numbers.',
  warningCredit: 'Warning credit must be less than limit credit',
  limitCredit: 'Limit credit must be higher than warning credit',
  maximumCredit: 'Limit credit must be less than the maximum limit',
  validateBookerId: '{_field_} must start with BKVN...',
  validateTtvVJ: '{_field_} must start with SJ...',
  bookerId: 'ID Booker',
  ttvVJ: 'Membership card',

  Email: 'Email',
  Password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  retypePassword: 'Retype Password',
  confirmation: 'password confirmation',
  username: 'Username',
  firstName: 'First name',
  lastName: 'Last name',
  isUnique: '{_field_} already in use.',
  phone: 'Phone',
  agencyName: 'Agency name',
  agencyOwner: 'Agency owner',
  agencyManager: 'Agency Manager',
  agencyWarningCredit: 'Warning credit',
  agencyLimitCredit: 'Limit credit',
  sendMonthReport: 'Send Monthly Report',
  userName: '{_field_} is wrong format. Please try again!',
  status: 'Status',
  dob: 'Date of birth',
  identityCardNumber: 'Identity Card Number',

  attachRoleForUserSuccessful: 'Attach succesful role for user.',
  attachRoleForUserError: 'Error attach role for user.',
  detachRoleForUserSuccessful: 'Detach succesful role for user.',
  detachRoleForUserError: 'Error detach role for user.',

  addAgencyToListManagementDuplicated: 'Agency already on the management list!',
  addAgencyToListManagementSuccess: 'Agency management updated successful!',
  addAgencyToListManagementError: 'Error agency management update',

  successful: 'The action was successful!',
  title: 'Success',

  // ANCHOR Login
  loginSuccessful: 'Login successful!',
  loginError: 'Login error!',
  logoutSuccessful: 'Logout successful!',
  welcome: 'Welcome',
  loginInfoIncorrect: 'Username or password is incorrect. Please try again.',
  accountDisabled: 'Account has been disabled.',
  auth: {
    passwordChanged: 'Password changed',
    PASSWORD_MUST_NOT_BE_THE_OLD_PASSWORD:
      'New password must not be the old password',
    INVALID_PASSWORD: 'Invalid password',
    // forgot password
    emailSent:
      'A OTP code has been sent to your email address. Please check and follow the detail instructions.',
    EMAIL_NOT_FOUND: 'Email not found',
    otpSuccess: 'Enter OTP code successful',
    INVALID_OTP: 'Invalid OTP',
  },

  'Departure and destination must be difference':
    'Departure and destination must be difference!',
  'Invalid date, please try again': 'Invalid date, please try again',
  'Please select departure / destination':
    'Please select departure / destination',
  'Please select departure date / arrival date':
    'Please select departure date / arrival date',

  success: 'Success!',
  error: 'Error!',
  errAction: 'Có lỗi khi thực hiện, vui lòng kiểm tra lại thông tin!',

  employee: {
    updatedStatusSuccess: 'Updated status succesful',
    updatedStatusError: 'Error update status',
    errorFetching: 'Error fetching list',
    createSuccess: 'Created employee successful',
    createError: 'Error create employee',
    updateSuccess: 'Updated employee successful',
    updateError: 'Error update employee',
    deleteSuccess: 'Deleted employee successful',
    deleteError: 'Error delete employee',
    exportSuccess: 'Export excel succesful',
    exportError: 'Error export excel',
    exportSampleSuccess: 'Export excel sample succesful',
    exportSampleError: 'Error export excel sample',
    importSuccess: 'Import excel succesful',
    importError: 'Error import excel',
  },
  agency: {
    edited: 'Updated successful',
    added: 'Created successful',
    deleted: 'Deleted successful',
    deactived: 'Deactived successful',
    actived: 'Actived successful',
    exported: 'Exported successful',
    addBankAccountsSuccess: 'Added bank accounts succesful',
    addBankAccountsError: 'Error add bank accounts',
    sendSuccess: 'Test email request successful',
  },
  flight: {
    createBookingSuccessful: 'Create booking succesful',
    paynowBookingSuccessful: 'Pay now succesful',
    multiCityMustBeDomestic:
      'Multi City flights only support domestic flights. Please use class booking VN feature.',
    errCheck: 'Please check again and enter complete information!',
    errMaxLengthPax: 'Passenger information is too long',
    errMissingDoB:
      "Please do not leave the passenger's year of birth blank when adding Passport information",
    errMaxLengthPaxContent:
      'Passenger information length exceeds the airline regulations',
    uAccountCannotPayNow:
      'Your agent account does not have the right to issue tickets immediately. Please contact your authorized dealer.',
  },

  promotionAg: {
    fetchPromotionError: 'Fetch promotion error',
    createPromotionSuccess: 'Create promotion succesful',
    createPromotionError: 'Create promotion error',
    updatePromotionSuccess: 'Update promotion succesful',
    updatePromotionError: 'Update promotion error',
    deletePromotionSuccess: 'Delete promotion succesful',
    deletePromotionError: 'Delete promotion error',
  },

  workSchedule: {
    fetchScheduleError: 'Fetch schedule error',
    createScheduleSuccess: 'Create schedule succesful',
    createScheduleError: 'Create schedule error',
  },

  reservation: {
    modifyDocumentsSuccess: 'Modify documents succesful',
    modifyDocumentsError: 'Modify documents error',
    voidTicketSuccess: 'Void tickets succesful',
    voidTicketError: 'Void tickets error',
    getCommissionsError: 'Error get commissions list',
    refundTicketsSuccess: 'Refund tickets succesful',
    refundTicketError: 'Refund tickets error',
    exchangeTicketsSuccess: 'Exchange tickets succesful',
    exchangeTicketError: 'Exchange tickets error',
    modifyRemarksSuccess: 'Send remarks succesful',
    modifyRemarksError: 'Send remarks error',
    shortenBookingError: 'Shorten booking error',
    shortenEticketError: 'Shorten eticket error',
    retrieveEticketError: 'Retrieve eticket error',
    sendQueuesSuccess: 'Send queues succesful',
    sendQueuesError: 'Send queues error',
    confirmSegmentsSuccess: 'Confirm segments succesful',
    confirmSegmentsError: 'Confirm segments error',
    osiFlightSuccess: 'OSI succesful',
    osiFlightError: 'OSI error',
    bookSpecialServicesSuccess: 'Book special services successful',
    bookSpecialServicesError: 'Error book special services',
    outDateExchangeTicketErrorTitle: 'Overdue eticket',
    outDateExchangeTicketError: 'Overdue to change ticket number',
    modifyFrequentFlyerSuccess:
      'Successfully edited membership card information',
    modifyFrequentFlyerError:
      'Failure to change membership card information, please check again',
    recalculatePriceSuccess: 'Recalculate price succesful',
    recalculatePriceError: 'Error recalculate price',
    saveRecalculatePriceSuccess: 'Save recalculate price succesful',
    saveRecalculatePriceError: 'Error save recalculate price',
  },

  saleReport: {
    createManually: {
      success: 'Create sale report succesful',
      error: 'Error create sale report error',
    },
    deleteManually: {
      success: 'Delete sale report succesful',
      error: 'Error delete sale report',
    },
    updateManually: {
      success: 'Updated sale report succesful',
      error: 'Error update sale report',
    },
    fetchListError: 'Error fetch sale report list',
  },

  accountPayable: {
    fetchListError: 'Error fetching account payable list',
    fetchDetailError: 'Error fetching account payable detail',
    updateSaleReportSuccess: 'Update sale report success',
    updateSaleReportError: 'Error update sale report',
    bookClosingSuccess: 'Book closing succesful',
    bookClosingError: 'Error book closing',
    exportSuccess: 'Export excel succesful',
    exportError: 'Error export excel',
  },

  settingAccount: {
    editBankAccountError: 'There was an error editing the bank account',
    editBankAccountSuccess: 'The bank account edit was successful.',

    deleteBankAccountSuccess: 'The bank account was successfully deleted',
    deleteBankAccountError: 'There was an error deleting the bank account',

    verifyBankAccountSuccess: 'The bank account verification was successful',
    verifyBankAccountError: 'Error verifying bank account',

    resendBankAccountSuccess: 'The bank account resend was successful',
    resendBankAccountError: 'Error resending bank account',

    setDefaultBankSuccess: 'Default bank set successfully',
    setAutoBankSuccess: 'Auto confirm transactions set successfully',
    setDefaultBankError: 'Error setting default bank',
    cantUnSetDefaultBank: 'Unable to unset default bank.',
    cantSetDefaultBank: 'Unable to set default bank',

    switchDefault: 'Switch default bank account successfully',
  },
  'Updated search information': 'Updated search information',
  'Cannot search for flights of past dates': 'Cannot search for flights of past dates',
  'Invalid date': 'Invalid date',
  'invalid date multi city': 'The departure time of the next journey must be after the departure time of the previous journey',
}
