export default {
  pageTitle: 'Electronic Ticket Template',
  sectionsTitle: {
    config: 'Electronic ticket template configs',
    template: 'Electronic ticket template',
  },
  showHideConfig: 'Show/ hide ticket config',
  agencyInfo: 'Agency information',
  showBackground: 'Show background',
  price: 'Price',
  holdTime: 'Hold time',
  ticketStatus: 'Booking status',
  bookingClass: 'Booking class',
  useEditor: 'Use Editor',
  paymentInfo: 'QR code payment information',
  qrBookingInfo: 'QR booking information',
  priceNetFare: 'Price + Fee and tax',
  priceDetail: 'Price detail',
  showAncillaries: 'Show ancillaries',
  showBackgroundColor: 'Background color',
  showColorByAirline: 'Color by airline',
  bankAccount: 'Bank account',
  phBankAccount: 'Select bank account',
  language: 'Language',
  color: 'Color',
  colors: {
    yellow: 'Yellow',
    red: 'Red',
    green: 'Green',
    blue: 'Blue',
    lightBlue: 'Light Blue',
    deepOceanBlue: 'Deep Ocean Blue (VNA)',
    black: 'Black',
    pink: 'Pink',
    indigo: 'Indigo',
    purple: 'Purple',
    orange: 'Orange',
    teal: 'Teal',
    gray: 'Gray',
  },
  eTicket: 'Electronic ticket',
  prepaymentNote: 'Complete your payment by: 6:30 pm on 14/06/2023',
  payBefore: 'Complete your payment by',
  clickToChangeStatus: 'Click to change the status of the ticket to',
  statusHold: 'HOLD',
  statusCancel: 'CANCEL',
  statusPaid: 'PAID',
  flightsInfo: {
    bookingID: 'PNR',
    trip1: '(Wednesday) 21/06/2023 Ha Noi - Ho Chi Minh',
    trip2: '(Monday) 28/06/2023 Ho Chi Minh - Ha Noi',
    departTime: 'Departure Time',
    arrivalTime: 'Arrival Time',
    terminal: 'Terminal',
    codeshareWith: 'Codeshare with',
    aircraft: 'Aircraft',
    distance: 'Distance (Miles)',
    duration: 'Duration',
    handBaggage: 'Carry on baggage',
    freeBaggage: 'Checked baggage',

    flight: 'Flight',
    transitIn: 'Transit in',
    transitTime: 'Transit time',
    airline: 'Airline',
  },
  na: 'N/A',
  pnrCodes: {
    // https://support.travelport.com/webhelp/uapi/Content/Air/Shared_Air_Topics/PNR_Status_Codes.htm
    HK: 'Holds confirmed',
  },
  paxTable: {
    no: 'No.',
    passenger: 'Passenger Name',
    additionalServices: 'Addon Service',
    pax1: '(Mr) VIET DUNG TRAN',
    pax2: '(Mstr) MINH DUC NGUYEN',
    pax3: '(Miss) LE PHUONG TRAN',
    bagsTicket1: 'SGN - HAN: 23KG checked baggage',
    bagsTicket2: 'SGN - HAN: 23KG checked baggage',
    bagsTicket3: '',
    ticketNumber: 'Ticket Number',

    trainTicketNumber: 'Ticket Number',
    trainName: 'Train',
    seatName: 'Seat',
    travelTime: 'Time',
  },
  ticketPrice: 'Fare Details',
  ticketAncillaries: 'Ancillaries',
  ticketNumber: 'Ticket Number',
  priceTable: {
    passenger: 'Passenger',
    fare: 'Fare',
    tax: 'Taxes and fees',
    total: 'Total',
    totalOfPrice: 'Total of Price',
  },
  note: {
    title: 'Note',
  },
  saveTicketConfigSuccess: 'Save ticket configs successfully.',
  saveTicketConfigErr: 'Save ticket configs not successfully.',
  exportTicketSuccess: 'Export PDF ticket face successfully.',
  exportTicketgErr: 'Export PDF ticket face not successfully.',
  pack: 'PC',
  oversized: 'oversized',
  ticketAlertWarning: {
    1: 'This is the ticket for information exchange between the agency and the customer.',
    2: 'The information is not 100% accurate for booking on the airline. Agency please check before sending to customers.',
  },
  clickChangeBookingClassBadge: 'Click to change booking class',
  errorBookingClassBadge: 'Booking class information may be incorrect, please correct it.',
  eticketTemplate: {
    modern: 'Modern',
    basic: 'Basic',
    title: 'Eticket template',
  },

  pageTitleTrain: 'Electronic Ticket Train Template',

  paxTypeCode1: 'Adult',
  paxTypeCode2: 'Children',
  paxTypeCode12: 'Student',
  paxTypeCode13: 'Old people',
  paxTypeCode6: 'Foreign Adult',
  paxTypeCode7: 'Foreign Children',
}
