export default {
  messBoard: 'Bảng tin',
  note: 'Lưu ý',
  notification: 'Thông báo',
  promotion: 'Khuyến mãi',
  title: 'Thông báo',
  sort: {
    original: 'Nguyên bản',
    asc: 'Tăng dần',
    desc: 'Giảm dần',
  },
  priorityPin: {
    HIGH: 'QUAN TRỌNG',
    MEDIUM: 'TRUNG BÌNH',
    LOW: 'THẤP',
    ALL: 'TẤT CẢ',
  },
  name: {
    HIGH: 'Quan trọng',
    MEDIUM: 'Trung bình',
    LOW: 'Thấp',
    ALL: 'Tất cả',
  },
  viewMore: 'Xem thêm',
  collapse: 'Thu gọn',
  detail: 'Chi tiết ',
  from: 'TỪ',
}
